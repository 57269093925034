<template>
  <!--    现货-->
  <div class="sel-main">
    <div class="sel">
      <div class="label">材质</div>
      <!--      <el-select class="ring-sel" :value="customCheck.goldColor" :popper-append-to-body="false" placeholder="请选择" @change="goldColor">-->
      <!--        <el-option :label="item.name" :value="item.value" :disabled="!item.optional" ></el-option>-->
      <!--      </el-select>-->
      <div class="item" :class="customCheck.goldColor == item.value ? 'check' : ''" v-show="item.optional" v-for="(item, index) in customizeFilterCondition.goldColor" :key="index" @click="goldColor(item.value)">{{item.value}}</div>
    </div>
    <div class="sel" v-if="customizeFilterCondition.isShowInsertSize">
      <div class="label">镶口</div>
      <el-select class="ring-sel" :value="customCheck.insertSize" :clearable="true" :popper-append-to-body="false" placeholder="请选择" @change="insertSize">
        <el-option :label="item.name" :value="item.value" :disabled="!item.optional" v-for="(item, index) in customizeFilterCondition.insertSize" :key="index"></el-option>
      </el-select>
    </div>
    <div class="sel" v-if="customizeFilterCondition.isShowHandSize">
      <div class="label">手寸</div>
      <el-select class="ring-sel" :value="customCheck.handSize" :clearable="true" :popper-append-to-body="false" placeholder="请选择" @change="handSize">
        <el-option :label="item.name" :value="item.value" :disabled="!item.optional" v-for="(item, index) in customizeFilterCondition.handSize" :key="index"></el-option>
      </el-select>
<!--      <div class="text">如何测手寸</div>-->
    </div>
    <div class="sel" v-if="customizeFilterCondition.isShowBraceletSize">
      <div class="label">内径</div>
      <el-select class="ring-sel" :value="customCheck.braceletSize" :clearable="true" :popper-append-to-body="false" placeholder="请选择" @change="handSize">
        <el-option :label="item.name" :value="item.value" :disabled="!item.optional" v-for="(item, index) in customizeFilterCondition.braceletSize" :key="index"></el-option>
      </el-select>
    </div>
    <div class="sel" v-if="customizeFilterCondition.isShowSStoneColor">
      <div class="label">主钻颜色</div>
      <el-select class="ring-sel" :value="customCheck.sStoneColor" :clearable="true" :popper-append-to-body="false" placeholder="请选择" @change="handSize">
        <el-option :label="item.name" :value="item.value" :disabled="!item.optional" v-for="(item, index) in customizeFilterCondition.sStoneColor" :key="index"></el-option>
      </el-select>
    </div>
    <div class="sel" v-if="customizeFilterCondition.isShowSStoneClarity">
      <div class="label">主钻净度</div>
      <el-select class="ring-sel" :value="customCheck.sStoneClarity" :clearable="true" :popper-append-to-body="false" placeholder="请选择" @change="handSize">
        <el-option :label="item.name" :value="item.value" :disabled="!item.optional" v-for="(item, index) in customizeFilterCondition.sStoneClarity" :key="index"></el-option>
      </el-select>
    </div>
    <div class="sel" v-if="customizeFilterCondition.isShowSStoneCut">
      <div class="label">主钻切工</div>
      <el-select class="ring-sel" :value="customCheck.sStoneCut" :clearable="true" :popper-append-to-body="false" placeholder="请选择" @change="handSize">
        <el-option :label="item.name" :value="item.value" :disabled="!item.optional" v-for="(item, index) in customizeFilterCondition.sStoneCut" :key="index"></el-option>
      </el-select>
    </div>
    <div class="sel">
      <div class="label">刻字</div>
      <div class="input">
        <input type="text" :value="customCheck.input" @input="inputChange" ref="madeInput" name="input" placeholder="15字以内的数字、字母、符号">
      </div>
      <div class="label fu">符号</div>
      <div class="tabs btn">
        <div class="tab" @click="tabBar('&')">&</div>
        <div class="tab" @click="tabBar('♡')">♡</div>
        <div class="tab" @click="tabBar('♂')">♂</div>
        <div class="tab" @click="tabBar('♀')">♀</div>
      </div>
    </div>
    <div class="sel">
      <div class="tottt">温馨提示:如您选择刻字，指圈较小的托，字太长会导致刻不下。</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'made',
    data() {
      return {
        customCheck: {
          // 手寸
          handSize: '',
          // 金成色
          goldColor: '',
          // 镶口,主钻大小
          insertSize: '',
          // 内径
          braceletSize: '',
          // 颜色
          sStoneColor:'',
          // 净度
          sStoneClarity: '',
          // 切工
          sStoneCut: '',
          // 刻字
          letteringFont: '',
          // 刻字值
          input: ''
        },
      }
    },
    props: {
      // 主数据
      customizeFilterCondition: {
        type: Object,
        default: null
      }
    },
    methods: {
      handSize(e){
        console.log(e)
        this.customCheck.handSize = e
        this.$emit('madechange', this.customCheck)
      },
      goldColor(e){
        this.customCheck.goldColor = e == this.customCheck.goldColor ? '' : e
        this.$emit('madechange', this.customCheck)
      },
      insertSize(e){
        console.log(e)
        this.customCheck.insertSize = e
        this.$emit('madechange', this.customCheck)
      },
      inputChange(e){
        console.log(e)
        console.log(this.customCheck.input)
        if (e.data.length > 15) return
      },
      tabBar(val) {
        let input = this.$refs.madeInput.value
        if(input.length > 15) return
        let inputLang = document.getElementsByName('input')
        let lang = inputLang[0].selectionStart
        let arr = input.split('')
        arr.splice(lang,0 , val)
        this.customCheck.input = arr.join('')
      }
    }
  }
</script>

<style scoped lang="scss">
  .sel-main {
    margin-bottom: 47px;
    border-top: 1px solid #F0F0F0;
    padding-top: 22px;
    user-select: none;

    .label {
      width: 70px;
      font-size: 14px;
      color: #999999;

      &.fu{
        margin-left: 30px;
      }
    }

    .sel {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 15px;

      .tottt{
        color: #FF8178;
        font-size: 12px;
      }

      .input{
        input{
          padding: 6px 10px;
          width: 210px;
          border: 1px solid #CCCCCC;
        }
      }

      .tabs{
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .tab{
          width: 30px;
          height: 30px;
          margin-right: 8px;
          border: 1px solid #CCCCCC;
          line-height: 30px;
          text-align: center;
        }
      }

      .text{
        font-size: 14px;
        color: #2644F1;
        margin-left: 20px;
        cursor: pointer;
      }

      .item{
        width: 60px;
        height: 30px;
        border: 1px solid #ccc;
        margin-right: 10px;
        text-align: center;
        line-height: 30px;
        color: #999999;
        font-size: 13px;

        &.check{
          border-color: #FF8178;
          background: #FDF4F4;
        }
      }

      ::v-deep .ring-sel {
        border: 1px solid #CCCCCC;
        width: 125px;

        .el-input__inner {
          border: 0;
          height: 30px;
        }

        .el-input__icon {
          line-height: 30px;
        }

        .el-select-dropdown__item.selected {
          color: #FF8178;
        }
      }
    }
  }
</style>
