<template>
  <div class="main">
    <div class="ring-head">
      <!--      图、视频、gia-->
      <div class="show">
        <div class="show-main" v-for="(item, index) in items.image" :key="index" v-show="imgIndex == index">
          <video v-if="item.fileType == 1" :src="item.image" controls="controls"></video>
          <img class="show-img" v-if="item.fileType == 0" :src="item.image" alt="">
        </div>

        <div class="items">
          <img class="item btn" :class="imgIndex == index ? 'set' : ''" v-show="item.fileType == 0" :src="item.image"
               v-for="(item, index) in items.image" :key="index" @mouseover="imgIndex = index">
        </div>
        <!--        锚点。。。-->
<!--        <div class="listss" id="details"></div>-->
      </div>
      <!--      价格详情-->
      <div class="details">
        <div class="title">{{items.title || '-'}}</div>
        <div class="price">
<!--          <div class="label">价格</div>-->
          <div class="money" v-show="price == ''"><span>￥</span>{{priceMin || '-'}} - {{priceMax || '-'}}</div>
          <div class="money" v-show="price != ''"><span>￥</span>{{price || '-'}}</div>
<!--          <div class="last">售出<span>{{items.salesVolume || '-'}}</span></div>-->
        </div>
        <Spor v-if="items.stockCount != 0" :filterCondition="filterCondition" @sporchange="sporchange"></Spor>
        <Made v-if="items.stockCount == 0" :customizeFilterCondition="customizeFilterCondition"
              @madechange="madechange"></Made>
        <div class="btn-main">
          <div class="btn" v-if="items.stockCount != 0" @click="checkQRcode">立即下单</div>
          <div class="btn" v-if="items.stockCount == 0" @click="checkQRcode">立即定制</div>
        </div>
      </div>
    </div>

    <div class="body-details">
      <div class="tab-main" id="details">
        <div class="tab sel btn">商品详情</div>
        <span>/</span>
<!--        <div class="tab btn" @click="goAnchor('')"><a href="#parameter">商品参数</a></div>-->
        <div class="tab btn"><a href="#parameter">商品参数</a></div>
      </div>

      <div class="details-img">
        <img :src="item" v-for="(item,index) in items.detailImage" :key="index" alt="">
      </div>
      <hr>
      <div class="tab-main sels" id="parameter">
<!--        <div class="tab btn" @click="goAnchor('#details')">商品详情</div>-->
        <div class="tab btn"><a href="#details">商品详情</a></div>
        <span>/</span>
        <div class="tab sel btn">商品参数</div>
      </div>
      <div class="ring-table">
        <div class="t-main">
          <div class="t-head">
            <div class="item">成色</div>
            <div class="item">货品分类</div>
            <div class="item">款式镶法</div>
            <div class="item">爪数/叶数</div>
            <div class="item">加工工艺</div>
          </div>
          <div class="t-body">
            <div class="item">{{items.goldColorType || '-'}}</div>
            <div class="item">-</div>
            <div class="item">{{items.styleSettingName || '-'}}</div>
            <div class="item">{{items.clawNumber || '-'}}</div>
            <div class="item">{{items.technology || '-'}}</div>
          </div>
        </div>
        <div class="t-main">
          <div class="t-head">
            <div class="item">款式风格</div>
            <div class="item">颈部造型</div>
            <div class="item">颈部花头</div>
            <div class="item">款式爪型</div>
            <div class="item">款式戒臂</div>
          </div>
          <div class="t-body">
            <div class="item">{{items.largeStyles || '-'}}</div>
            <div class="item">{{items.neckModeling || '-'}}</div>
            <div class="item">{{items.neckFlower || '-'}}</div>
            <div class="item">{{items.clawType || '-'}}</div>
            <div class="item">{{items.styleArm || '-'}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="popup-bg" v-show="getorder" @click="downPopup"></div>

    <div class="popup-main" v-show="getorder">
      <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/details/cutdown.png" alt="" class="cut-down" @click="downPopup">
      <div class="border-main">
        <img class="qr" src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/details/qrcode.png" alt="">
        <div class="qrcode" ref="qrCodeUrl"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import Ring from '@/api/ring'
  import Spor from './components/spor'
  import Made from './components/made'
  import QRCode from 'qrcodejs2'
  // import PicZoom from 'vue-piczoom'
  export default {
    name: 'ringDetails',
    data () {
      return {
        relationId: '',
        standardNumber: '',
        // 数据
        items: '',
        // 图片
        imgIndex: 0,
        // 现货
        filterCondition: {},
        filCheck: '',
        // 定制数据
        customizeFilterCondition: {},
        customCheck: '',
        // 现货的商品列表
        shopItem: '',
        // 价钱区间
        priceMax: '',
        priceMin: '',
        // 选择后的价格
        price: '',
        getorder: false,
        relationIds: ''
      }
    },
    components: {
      Spor,
      Made,
      // PicZoom
    },
    computed: {
      shopInit: function () {
        let shop = this.$store.state.shop
        let relationId = ''
        if (shop && this.$store.state.SequenceNo) {
          relationId = shop.relationId
        }
        return relationId
      }
    },
    watch: {
      shopInit: {
        handler (newValue, oldValue) {
          if (this.relationIds != '' || newValue == '') return
          this.relationIds = newValue
          if (newValue != '') {
            let shop = this.$store.state.shop
            this.$getif(shop.vipType)
            // this.getInit()
          }
        },
        immediate: true,
      }
    },
    mounted () {
      let relationId = this.$route.query.relationId
      let standardNumber = this.$route.query.standardNumber
      this.relationId = relationId
      this.standardNumber = standardNumber
      this.getInit()
      this.getCode()
    },
    methods: {
      downPopup() {
        this.getorder = false
      },
      checkQRcode() {
        this.getorder = true
      },
      getCode() {
        // 获取地址
        let url = window.location.protocol + '//' + window.location.host
        // 替换字符
        // url = url.replace(/\/\/web/, '//')
        url = url.replace(/palmes.shop/, "pz-usa.cn")
        // let str = 'http://15103523218.jzker.cn'
        // let mobile = 'http://15103523218.gil-usa.com'
        let uri = `${url}/#/pages/ring/details?relationId=${this.relationId}&standardNumber=${this.standardNumber}`
        console.log(uri,"跳转路由");
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: uri, // 需要转换为二维码的内容
          width: 260,
          height: 260,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
      },
      // 获取详情数据
      getInit () {
        let params = {
          RelationId: this.relationId,
          StandardNumber: this.standardNumber
        }

        Ring.detail(params).then(res => {
          console.log(res)
          if (res.status == 200) {
            let data = res.result
            // stockCount库存
            if (data.stockCount == 0) {
              this.priceMax = data.customizeFilterCondition.priceMax
              this.priceMin = data.customizeFilterCondition.priceMin
            } else {
              this.getstock(data.styleLibraryId)
              this.priceMax = data.filterCondition.priceMax
              this.priceMin = data.filterCondition.priceMin
            }
            document.title = data.title
            // 现货
            this.filterCondition = data.filterCondition
            // 定制
            this.customizeFilterCondition = data.customizeFilterCondition
            this.items = data
          }
        })
      },
      // 获取现货(如果这个接口返回为空，那就是莫得现货，显示定制接口)
      getstock (id) {
        let params = {
          RelationId: this.relationId,
          StyleLibraryId: id
        }

        Ring.stock(params).then(res => {
          console.log(res)
          if (res.status == 200) {
            let data = res.result
            this.shopItem = data
          }
        })
      },
      // 现货选择
      sporchange (obj) {
        console.log(obj)
        this.filCheck = obj
        this.getspor()
      },
      getspor () {
        // 提交的数据
        let shop = this.shopItem
        // 手寸
        console.log('getspor')
        console.log(this.filCheck)
        let handSize = this.filCheck.handSize
        var arr = []
        arr = shop.map(item => {
          if (handSize == '') {
            return item
          }
          if (item.handSize == handSize) {
            return item
          }
        }).filter(Boolean)
        // 金成色
        let goldColor = this.filCheck.goldColor
        arr = arr.map(item => {
          if (goldColor == '') {
            return item
          }
          if (item.goldColor == goldColor) {
            return item
          }
        }).filter(Boolean)
        // 主钻大小
        let insertSize = this.filCheck.insertSize
        arr = arr.map(item => {
          if (insertSize == '') {
            return item
          }
          if (item.insertSize == insertSize) {
            return item
          }
        }).filter(Boolean)
        // 内径
        let braceletSize = this.filCheck.braceletSize
        arr = arr.map(item => {
          if (braceletSize == '') {
            return item
          }
          if (item.braceletSize == braceletSize) {
            return item
          }
        }).filter(Boolean)
        // 颜色
        let sStoneColor = this.filCheck.sStoneColor
        arr = arr.map(item => {
          if (sStoneColor == '') {
            return item
          }
          if (item.sStoneColor == sStoneColor) {
            return item
          }
        }).filter(Boolean)
        // 净度
        let sStoneClarity = this.filCheck.sStoneClarity
        arr = arr.map(item => {
          if (sStoneClarity == '') {
            return item
          }
          if (item.sStoneClarity == sStoneClarity) {
            return item
          }
        }).filter(Boolean)
        // 切工
        let sStoneCut = this.filCheck.sStoneCut
        arr = arr.map(item => {
          if (sStoneCut == '') {
            return item
          }
          if (item.sStoneCut == sStoneCut) {
            return item
          }
        }).filter(Boolean)
        let arrprice = arr.map(item => {
          return item.price
        })
        this.priceMin = Math.min(...arrprice)
        this.priceMax = Math.max(...arrprice)

        if ((this.filterCondition.isShowHandSize ? handSize != '' : handSize == '') && goldColor != '' && (this.filterCondition.isShowInsertSize ? insertSize != '' : insertSize == '') && (this.filterCondition.isShowBraceletSize ? braceletSize != '' : braceletSize == '') && (this.filterCondition.isShowSStoneColor ? sStoneColor != '' : sStoneColor == '') && (this.filterCondition.isShowSStoneClarity ? sStoneClarity != '' : sStoneClarity == '') && (this.filterCondition.isShowSStoneCut ? sStoneCut != '' : sStoneCut == '')) {
          this.price = arr[0].price
        } else {
          this.price = ''
        }
        // console.log('arr',arr);
        this.setItem(arr)
      },
      setItem (arr) {
        let handSize = arr.map((item, idnex) => {
          return item.handSize
        })
        // 去重，获取到选中商品的值
        handSize = this.clearitem(handSize)
        // 先八所有定义为不显示
        this.filterCondition.handSize.map((item, index) => {
          this.$set(item, 'optional', false)
        })
        // 再根据条件显示
        handSize.map((it, ind) => {
          this.filterCondition.handSize.map((item, index) => {
            if (item.value == it) {
              this.$set(item, 'optional', true)
            }
          })
        })
        // console.log(handSize);
        // 金成色
        let goldColor = arr.map((item, idnex) => {
          return item.goldColor
        })
        goldColor = this.clearitem(goldColor)
        this.filterCondition.goldColor.map((item, index) => {
          this.$set(item, 'optional', false)
        })
        goldColor.map((it, ind) => {
          this.filterCondition.goldColor.map((item, index) => {
            if (item.value == it) {
              this.$set(item, 'optional', true)
            }
          })
        })
        // console.log(goldColor);
        // 金成色
        let insertSize = arr.map((item, idnex) => {
          return item.insertSize
        })
        insertSize = this.clearitem(insertSize)
        this.filterCondition.insertSize.map((item, index) => {
          this.$set(item, 'optional', false)
        })
        insertSize.map((it, ind) => {
          this.filterCondition.insertSize.map((item, index) => {
            if (item.value == it) {
              this.$set(item, 'optional', true)
            }
          })
        })
        // console.log(insertSize);

        // 内径
        if (this.filterCondition.isShowBraceletSize) {
          let braceletSize = arr.map((item, idnex) => {
            return item.braceletSize
          })
          braceletSize = this.clearitem(braceletSize)
          this.filterCondition.braceletSize.map((item, index) => {
            this.$set(item, 'optional', false)
          })
          braceletSize.map((it, ind) => {
            this.filterCondition.braceletSize.map((item, index) => {
              if (item.value == it) {
                this.$set(item, 'optional', true)
              }
            })
          })
        }
        // 主钻颜色
        if (this.filterCondition.isShowSStoneColor) {
          let sStoneColor = arr.map((item, idnex) => {
            return item.sStoneColor
          })
          sStoneColor = this.clearitem(sStoneColor)
          this.filterCondition.sStoneColor.map((item, index) => {
            this.$set(item, 'optional', false)
          })
          sStoneColor.map((it, ind) => {
            this.filterCondition.sStoneColor.map((item, index) => {
              if (item.value == it) {
                this.$set(item, 'optional', true)
              }
            })
          })
        }
        // 主钻净度
        if (this.filterCondition.isShowSStoneClarity) {
          let sStoneClarity = arr.map((item, idnex) => {
            return item.sStoneClarity
          })
          sStoneClarity = this.clearitem(sStoneClarity)
          this.filterCondition.sStoneClarity.map((item, index) => {
            this.$set(item, 'optional', false)
          })
          sStoneClarity.map((it, ind) => {
            this.filterCondition.sStoneClarity.map((item, index) => {
              if (item.value == it) {
                this.$set(item, 'optional', true)
              }
            })
          })
        }
        // 主钻切工
        if (this.filterCondition.isShowSStoneCut) {
          let sStoneCut = arr.map((item, idnex) => {
            return item.sStoneCut
          })
          sStoneCut = this.clearitem(sStoneCut)
          this.filterCondition.sStoneCut.map((item, index) => {
            this.$set(item, 'optional', false)
          })
          sStoneCut.map((it, ind) => {
            this.filterCondition.sStoneCut.map((item, index) => {
              if (item.value == it) {
                this.$set(item, 'optional', true)
              }
            })
          })
        }
      },
      // 去重
      clearitem (arr) {
        var obj = {}
        arr = arr.reduce(function (item, next) {
          obj[next] ? '' : obj[next] = true && item.push(next)
          return item
        }, [])
        return arr
      },
      // 定制选择
      madechange (obj) {
        this.customCheck = obj
        this.getmade()
      },
      getmade () {
        let data = this.customCheck
        let customizeFilterCondition = this.customizeFilterCondition
        // 手寸
        if (this.customizeFilterCondition.isShowHandSize) {
          if (data.handSize == '') {
            this.price = ''
            return
          }
        }
        // 镶口
        if (this.customizeFilterCondition.isShowInsertSize) {
          if (data.insertSize == '') {
            this.price = ''
            return
          }
        }
        // 内径
        if (this.customizeFilterCondition.isShowBraceletSize) {
          if (data.braceletSize == '') {
            this.price = ''
            return
          }
        }
        // 金成色
        if (data.goldColor == '') {
          this.price = ''
          return
        }
        // 颜色
        if (this.customizeFilterCondition.isShowSStoneColor) {
          if (data.sStoneColor == '') {
            this.price = ''
            return
          }
        }
        // 净度
        if (this.customizeFilterCondition.isShowSStoneClarity) {
          if (data.sStoneClarity == '') {
            this.price = ''
            return
          }
        }
        // 切工
        if (this.customizeFilterCondition.isShowSStoneCut) {
          if (data.sStoneCut == '') {
            this.price = ''
            return
          }
        }

        var params = {
          RelationId: this.relationId,
          StyleLibraryId: this.items.styleLibraryId,
          InsertSize: this.customCheck.insertSize,
          BraceletSize: this.customCheck.braceletSize,
          HandSize: this.customCheck.handSize,
          GoldColor: this.customCheck.goldColor
        }
        // console.log(params);
        Ring.customized(params).then(res => {
          console.log(res)
          if (res.status == 200) {
            this.price = res.result.price
          }
        })
      },
      goAnchor (selector) {
        var anchor = document.querySelector(selector)
        //   没有动画
        //   document.documentElement.scrollTop = anchor.offsetTop
        //   有动画
        this.scrollTo(anchor.offsetTop, 500)
      },
      scrollTo (y, duration) {
        /* y:目标纵坐标,duration:时间(毫秒) */
        console.log('y', y)
        // var scrollTop = document.body.scrollTop /* 页面当前滚动距离 */
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
        console.log('scrollTop', scrollTop)

        var distance = y - scrollTop /* 结果大于0,说明目标在下方,小于0,说明目标在上方 */
        console.log('distance', distance)

        var scrollCount = duration / 10 /* 10毫秒滚动一次,计算滚动次数 */

        var everyDistance = parseInt(
          distance / scrollCount
        ) /* 滚动距离除以滚动次数计算每次滚动距离 */

        for (
          var index = 1;
          index <= scrollCount;
          index++ /* 循环设置scrollBy事件,在duration之内,完成滚动效果 */
        ) {
          setTimeout(function () {
            window.scrollBy(0, everyDistance)
          }, 10 * index)
        }
        let deviation = y - scrollCount * everyDistance
        console.log(deviation)
        window.scrollBy(0, deviation)
      }
    }
  }
</script>

<style scoped lang="scss">
  @mixin dis-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .main {
    .ring-head {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 40px 0 80px;
      border-bottom: 1px solid #000000;

      .show {
        margin-right: 154px;
        min-width: 500px;

        .show-img {
          width: 500px;
          height: 500px;

          &.GIA {
            width: 100%;
            height: auto;
          }
        }

        .gia-loading {
          width: 60px;
          height: 60px;
          animation: myRotate 1.9s linear infinite;
        }

        .show-main {
          width: 500px;
          height: 500px;
          /*background: url("../../assets/er.jpg");*/
          background-repeat: no-repeat;
          background-size: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          ::v-deep .el-carousel__container {
            width: 500px;
          }

          ::v-deep .el-carousel__indicators--outside {
            display: none;
          }

          ::v-deep .el-carousel__arrow.el-carousel__arrow--left {
            display: none !important;
          }

          ::v-deep .el-carousel__arrow.el-carousel__arrow--right {
            display: none !important;
          }

          video {
            width: 500px;
            height: 500px;
          }
        }

        iframe {
          width: 500px;
          height: 500px;
        }

        .items {
          margin-top: 20px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .item {
            width: 65px;
            height: 65px;
            border: 1px solid #D9D9D9;
            line-height: 65px;
            text-align: center;
            font-size: 14px;
            color: #333333;
            margin: 0 7.5px;

            &.set {
              border-color: #FF8178;
            }
          }
        }
      }

      .details {
        width: 100%;
        padding: 20px 0;

        .title {
          font-size: 24px;
          font-weight: 600;
          color: #333333;
          margin-bottom: 27px;
          font-family: SimSun;
        }

        .label {
          width: 50px;
          font-size: 14px;
          color: #999999;
        }

        .price {
          @include dis-start;
          align-items: center;
          margin-bottom: 40px;

          .money {
            font-size: 24px;
            font-weight: 600;
            color: #FF8178;

            span {
              font-size: 17px;
            }
          }

          .last {
            margin-left: 60px;
            font-size: 14px;
            color: #999999;

            span {
              color: #FF8178;
              font-size: 14px;
              margin-left: 10px;
            }
          }
        }

        .diamond {
          padding: 22px 0;
          border-top: 1px solid #F0F0F0;
          border-bottom: 1px solid #F0F0F0;
          color: #999999;
          margin-top: 40px;
          margin-bottom: 20px;

          .ites {
            @include dis-start;
            margin-bottom: 9px;

            &:last-child {
              margin: 0;
            }
          }
        }

        .btn-main {
          @include dis-start;

          .btn {
            width: 240px;
            height: 38px;
            text-align: center;
            border: 1px solid #FF8178;
            line-height: 38px;
            font-size: 14px;
            color: #FFFFFF;
            background: #FF8178;
            margin-right: 10px;

            &.nb {
              color: #FF8178;
              background: #ffffff;
            }
          }
        }

        .remind {
          margin-top: 30px;
          @include dis-start;

          .text {
            color: #FF8178;
            font-size: 14px;
          }
        }

        .protocol {
          width: 474px;
          color: #808080;
          font-size: 14px;
          margin-top: 15px;
        }
      }
    }

    .body-details {
      .tab-main {
        width: 900px;
        padding: 25px 0;
        margin: 0 auto;
        @include dis-start;

        a {
          color: #333333;
          text-decoration: none;
        }

        span {
          color: #999999;
          margin: 0 20px;
        }

        .tab {
          color: #333333;

          &.sel {
            color: #FF8178;
          }
        }
      }

      .details-img {
        width: 900px;
        margin: 0 auto;
        padding-bottom: 70px;

        img {
          width: 100%;
        }
      }

      hr {
        background: #fff;
        border-top: 1px solid #000;
        margin: 0;
      }

      .ring-table {
        padding-bottom: 30px;

        .t-main {
          margin-bottom: 50px;
          border-bottom: 1px solid #E5E5E5;

          .t-head {
            background: #F5F5F5;
            height: 35px;
            line-height: 35px;
            @include dis-start;
            padding: 0 100px;

            .item {
              font-size: 13px;
              color: #999999;
              min-width: 20%;
              text-align: center;
            }
          }

          .t-body {
            background: #FFFFFF;
            height: 35px;
            line-height: 35px;
            @include dis-start;
            padding: 0 100px;

            .item {
              font-size: 13px;
              color: #333333;
              min-width: 20%;
              text-align: center;
            }
          }
        }
      }
    }

    .popup-bg{
      width: 100vw;
      height: 100vh;
      background: rgba(0,0,0,.5);
      position: fixed;
      top: 0;
      left: 0;
    }

    .popup-main{
      width: 400px;
      height: 400px;
      position: fixed;
      top: calc(50vh - 200px);
      left: calc(50vw - 200px);
      background: #FFF;

      .cut-down{
        position: absolute;
        top: -50px;
        right: 0px;
        width: 28px;
        height: 28px;
        cursor: pointer;
      }

      .border-main{
        padding: 16px;
        width: 100%;
        height: 100%;
        position: relative;

        &::before{
          content: '';
          width: 260px;
          height: 1px;
          position: absolute;
          top: 16px;
          left: calc(50% - 130px);
          background: #FFF;
        }

        .qr{
          width: 220px;
          height: 77px;
          position: absolute;
          top: -38px;
          left: calc(50% - 110px);
        }

        .qrcode{
          width: 100%;
          height: 100%;
          border: 1px solid #FF8178;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;

          img{
            width: 270px;
            height: 270px;
          }
        }
      }
    }
  }
</style>
