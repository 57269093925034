<template>
  <!--    现货-->
  <div class="sel-main">
    <div class="sel">
      <div class="label">材质</div>
<!--      <el-select class="ring-sel" :value="filCheck.goldColor" :popper-append-to-body="false" placeholder="请选择" @change="goldColor">-->
<!--        <el-option :label="item.name" :value="item.value" :disabled="!item.optional" ></el-option>-->
<!--      </el-select>-->
      <div class="item" :class="filCheck.goldColor == item.value ? 'check' : ''" v-for="(item, index) in filterCondition.goldColor" :key="index" @click="goldColor(item.value)">{{item.value}}</div>
    </div>
    <div class="sel" v-if="filterCondition.isShowInsertSize">
      <div class="label">镶口</div>
      <el-select class="ring-sel" :value="filCheck.insertSize" :clearable="true" :popper-append-to-body="false" placeholder="请选择" @change="insertSize">
        <el-option :label="item.name" :value="item.value" :disabled="!item.optional" v-for="(item, index) in filterCondition.insertSize" :key="index"></el-option>
      </el-select>
    </div>
    <div class="sel" v-if="filterCondition.isShowHandSize">
      <div class="label">手寸</div>
      <el-select class="ring-sel" :value="filCheck.handSize" :clearable="true" :popper-append-to-body="false" placeholder="请选择" @change="handSize">
        <el-option :label="item.name" :value="item.value" :disabled="!item.optional" v-for="(item, index) in filterCondition.handSize" :key="index"></el-option>
      </el-select>
<!--      <div class="text">如何测手寸</div>-->
    </div>
    <div class="sel" v-if="filterCondition.isShowBraceletSize">
      <div class="label">内径</div>
      <el-select class="ring-sel" :value="filCheck.braceletSize" :clearable="true" :popper-append-to-body="false" placeholder="请选择" @change="braceletSize">
        <el-option :label="item.name" :value="item.value" :disabled="!item.optional" v-for="(item, index) in filterCondition.braceletSize" :key="index"></el-option>
      </el-select>
    </div>
    <div class="sel" v-if="filterCondition.isShowSStoneColor">
      <div class="label">主钻颜色</div>
      <el-select class="ring-sel" :value="filCheck.sStoneColor" :clearable="true" :popper-append-to-body="false" placeholder="请选择" @change="sStoneColor">
        <el-option :label="item.name" :value="item.value" :disabled="!item.optional" v-for="(item, index) in filterCondition.sStoneColor" :key="index"></el-option>
      </el-select>
    </div>
    <div class="sel" v-if="filterCondition.isShowSStoneClarity">
      <div class="label">主钻净度</div>
      <el-select class="ring-sel" :value="filCheck.sStoneClarity" :clearable="true" :popper-append-to-body="false" placeholder="请选择" @change="sStoneClarity">
        <el-option :label="item.name" :value="item.value" :disabled="!item.optional" v-for="(item, index) in filterCondition.sStoneClarity" :key="index"></el-option>
      </el-select>
    </div>
    <div class="sel" v-if="filterCondition.isShowSStoneCut">
      <div class="label">主钻切工</div>
      <el-select class="ring-sel" :value="filCheck.sStoneCut" :clearable="true" :popper-append-to-body="false" placeholder="请选择" @change="sStoneCut">
        <el-option :label="item.name" :value="item.value" :disabled="!item.optional" v-for="(item, index) in filterCondition.sStoneCut" :key="index"></el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'spor',
    data() {
      return {
        filCheck: {
          // 手寸
          handSize: '',
          // 金成色
          goldColor: '',
          // 镶口,主钻大小
          insertSize: '',
          // 内径
          braceletSize: '',
          // 颜色
          sStoneColor:'',
          // 净度
          sStoneClarity: '',
          // 切工
          sStoneCut: '',
          // 刻字
          letteringFont: '',
          // 刻字值
          input: ''
        },
      }
    },
    props: {
      // 主数据
      filterCondition: {
        type: Object,
        default: null
      }
    },
    methods: {
      // 手寸
      handSize(e){
        console.log(e)
        this.filCheck.handSize = e
        this.$emit('sporchange', this.filCheck)
      },
      // 金成色
      goldColor(e){
        this.filCheck.goldColor = e == this.filCheck.goldColor ? '' : e
        this.$emit('sporchange', this.filCheck)
      },
      // 镶口
      insertSize(e){
        console.log(e)
        this.filCheck.insertSize = e
        this.$emit('sporchange', this.filCheck)
      },
      // 内径
      braceletSize(e){
        this.filCheck.braceletSize = e
        this.$emit('sporchange', this.filCheck)
      },
      // 颜色
      sStoneColor(e){
        this.filCheck.sStoneColor = e
        this.$emit('sporchange', this.filCheck)
      },
      // 净度
      sStoneClarity(e){
        this.filCheck.sStoneClarity = e
        this.$emit('sporchange', this.filCheck)
      },
      // 切工
      sStoneCut(e){
        this.filCheck.sStoneCut = e
        this.$emit('sporchange', this.filCheck)
      }
    }
  }
</script>

<style scoped lang="scss">
  .sel-main {
    margin-bottom: 47px;
    border-top: 1px solid #F0F0F0;
    padding-top: 22px;
    user-select: none;

    .label {
      width: 70px;
      font-size: 14px;
      color: #999999;
    }

    .sel {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 15px;

      .text{
        font-size: 14px;
        color: #2644F1;
        margin-left: 20px;
        cursor: pointer;
      }

      .item{
        width: 60px;
        height: 30px;
        border: 1px solid #ccc;
        margin-right: 10px;
        text-align: center;
        line-height: 30px;
        color: #999999;
        font-size: 13px;

        &.check{
          border-color: #FF8178;
          background: #FDF4F4;
        }
      }

      ::v-deep .ring-sel {
        border: 1px solid #CCCCCC;
        width: 125px;

        .el-input__inner {
          border: 0;
          height: 30px;
        }

        .el-input__icon {
          line-height: 30px;
        }

        .el-select-dropdown__item.selected {
          color: #FF8178;
        }
      }
    }
  }
</style>
